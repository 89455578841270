import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {CHART_OPTIONS} from "../../config";
import {useEffect, useState} from "react";
import InvoiceService from "../../services/InvoiceService";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {all} from "axios";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export  const ContractorDashboard = () => {
    const [pastRewards, setPastRewards] = useState([]);
    const [allInvoicesLoaded, setAllInvoicesLoaded] = useState(false);
    const [products, setProducts] = useState([]);
    const [allProductsLoaded, setAllProductsLoaded] = useState(false);

    const [lowYtd, setLowYtd] = useState(0);
    const [highYtd, setHighYtd] = useState(0);
    const [earnedYtd, setEarnedYtd] = useState(0);
    const [invoiceYtd, setInvoiceYtd] = useState(0);
    const [lowAllTime, setLowAllTime] = useState(0);
    const [highAllTime, setHighAllTime] = useState(0);
    const [earnedAllTime, setEarnedAllTime] = useState(0);
    const [spentAllTime, setSpentAllTime] = useState(0);

    const [ytdDataPoints, setYtdDataPoints] = useState([]);
    const [ytdDataLabels, setYtdDataLabels] = useState([]);
    const [allTimeDataPoints, setAllTimeDataPoints] = useState([]);
    const [allTimeDataLabels, setAllTimeDataLabels] = useState([]);

    const [initialized, setInitialized] = useState(false);

    const [ytdSelected, setYtdSelected] = useState(false);

    const navigate = useNavigate();

    const {user} = useSelector((state) => state.auth);

    useEffect(() => {
        InvoiceService.getAll({pp: 4, ppp: 4}).then(data => {
            let rewardsEarnedYtd = 0;
            let spentTotal = 0;
            for (let i = 0; i < data.rewardsYtd.length; i++) {
                rewardsEarnedYtd += parseFloat(data.rewardsYtd[i].invoice_reward_total) * parseFloat('0.' + data.rewardsYtd[i].invoice_reward_value);
                spentTotal += parseFloat(data.rewardsYtd[i].invoice_reward_total);
            }
            setEarnedYtd(rewardsEarnedYtd.toFixed(2));
            setInvoiceYtd(spentTotal.toFixed(2));

            setPastRewards(data.invoices)

            if (data.invoices.length === data.totalInvoices) {
                setAllInvoicesLoaded(true);
            }

            setProducts(data.products);

            if (data.products.length === data.totalProducts) {
                setAllProductsLoaded(true);
            }

            setLowYtd(data.min);
            setHighYtd(data.max);

            let rewardsEarnedAllTime = 0;
            let spentTotalAllTime = 0;
            for (let i = 0; i < data.rewardsAllTime.length; i++) {
                rewardsEarnedAllTime += parseFloat(data.rewardsAllTime[i].invoice_year_total) * parseFloat('0.' + data.rewardsAllTime[i].invoice_reward_value);
                spentTotalAllTime += parseFloat(data.rewardsAllTime[i].invoice_year_total);
            }
            setEarnedAllTime(rewardsEarnedAllTime.toFixed(2));
            setSpentAllTime(spentTotalAllTime.toFixed(2));

            setLowAllTime(data.allTimeMin);
            setHighAllTime(data.allTimeMax);

            let ytdData = data.rewardsYtd.map(reward => {
                return Math.round((reward.invoice_reward_total - lowYtd) / (highYtd - lowYtd) * 10);
            });

            let allTimeData = data.rewardsAllTime.map(reward => {
                return Math.round((reward.invoice_reward_total - lowAllTime) / (highAllTime - lowAllTime) * 10);
            });

            let ytdLabels = data.rewardsYtd.map(reward => {
                return `${reward.invoice_month}`;
            });

            let allTimeLabels = data.rewardsAllTime.map(reward => {
                return `${reward.invoice_year}`;
            });

            if (ytdData.length === 1) {
                ytdData = [0, 1];

                let date = new Date(user.user_timestamp);

                ytdLabels = [date.toLocaleString('default', {month: 'short'}), ytdLabels[0]];
            } else if (ytdData => ytdData.every(val => val === ytdData[0])) {
                ytdData = ytdData.map(function(item) {
                    return 0.5;
                });
            }

            if (allTimeData.length === 1) {
                allTimeData = [0, 1];

                allTimeLabels = [allTimeLabels[0], allTimeLabels[0]];
            } else if (allTimeData => allTimeData.every(val => val === allTimeData[0])) {
                allTimeData = allTimeData.map(function(item) {
                    return 0.5;
                })
            }

            setYtdDataPoints(ytdData);
            setYtdDataLabels(ytdLabels);

            setAllTimeDataPoints(allTimeData);
            setAllTimeDataLabels(allTimeLabels);

            setInitialized(true);
        });
    }, []);

    return (
        <div className="contractor-dashboard">
            {initialized && earnedAllTime != 0 && ytdDataPoints && allTimeDataPoints && (
                <div className="line-graph-container">
                    <div className="time-selection-container">
                        <div className={"button " + (ytdSelected ? 'active' : '')} onClick={() => setYtdSelected(true)}>
                            Year to date
                        </div>

                        <div className={"button " + (!ytdSelected ? 'active' : '')} onClick={() => setYtdSelected(false)}>
                            All time
                        </div>
                    </div>

                    <div className="totals-container">
                        <div className="earned-text">
                            Earned this year
                        </div>

                        <div className="earned-total">
                            ${earnedAllTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>

                        <div className="total-purchase">
                            Total purchase amount ${spentAllTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                    </div>

                    <div className={"dashboard-graph " + (ytdSelected ? 'active' : '')}>
                        <Line options={CHART_OPTIONS} data={{
                            datasets: [{
                                data: ytdDataPoints,
                                borderWidth: 6,
                                // fill: false,
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                            }],
                            labels: ytdDataLabels,
                        }} />
                    </div>

                    <div className={"dashboard-graph " + (!ytdSelected ? 'active' : '')}>
                        <Line options={CHART_OPTIONS} data={{
                            datasets: [{
                                data: allTimeDataPoints,
                                borderWidth: 6,
                                fill: false,
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                            }],
                            labels: allTimeDataLabels,
                        }} />
                    </div>

                </div>
            )}

            <div className="time-selection-container">
                <div className={"button " + (ytdSelected ? 'active' : '')} onClick={() => setYtdSelected(true)}>
                    Year to date
                </div>

                <div className={"button " + (!ytdSelected ? 'active' : '')} onClick={() => setYtdSelected(false)}>
                    All time
                </div>
            </div>

            <div className="grid-50">
                <div className="card-container">
                    <div className="card-header">
                        <div className="title">
                            Approved Rewards
                        </div>

                        {!allInvoicesLoaded && (
                            <div className="view-more" onClick={() => navigate('/past-rewards')}>
                                View More
                            </div>
                        )}
                    </div>

                    <div className="card-body">
                        {(initialized && pastRewards.length === 0) ? (
                            <div className="row">
                                <div className="top">
                                    <h2 className={'card-title'}>No Rewards</h2>
                                </div>
                            </div>
                        ) : null}

                        {pastRewards.map((invoice, index) => {
                            return (
                                <div className="row" key={'recent-invoice-' + index}>
                                    <div className="top">
                                        <div className="left">
                                            {invoice.contractor.profile_company} - {invoice.contractor.contractor_title}
                                        </div>

                                        <div className="right">
                                            ${invoice.invoice_reward_total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>
                                    </div>

                                    <div className="bottom">
                                        <div className="left">
                                            {invoice.invoice_date}
                                            <span className="dot">•</span>
                                            <span className={invoice.invoice_status.toLowerCase()}>{invoice.invoice_status}</span>
                                        </div>

                                        <div className="right">
                                            <span className="box">
                                                +${(invoice.invoice_reward_total * ('0.' + invoice.invoice_reward_value)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>

                <div className="card-container">
                    <div className="card-header">
                        <div className="title">
                            Products
                        </div>

                        {!allProductsLoaded && (
                            <div className="view-more" onClick={() => navigate('/products')}>
                                View More
                            </div>
                        )}
                    </div>

                    <div className="card-body">
                        {(initialized && products.length === 0) ? (
                            <div className="row">
                                <div className="top">
                                    <h2 className={'card-title'}>No Products</h2>
                                </div>
                            </div>
                        ) : null}

                        {products.map((product, index) => {
                            return (
                                <div className="row" key={'product-' + index}>
                                    <div className="top">
                                        <div className="left">
                                            Product #{product.invoice_item_item_id} &nbsp; <span className="description">{product.invoice_item_description}</span>
                                        </div>

                                        <div className="right">
                                            ${(product.invoice_item_price * product.invoice_item_quantity).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>
                                    </div>

                                    <div className="bottom">
                                        <div className="left">Statement #{product.invoice_number} &nbsp;&nbsp; Qty: {product.total_quantity}
                                            <span className="dot">•</span>
                                            <span className={product.invoice_status.toLowerCase()}>{product.invoice_status}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
